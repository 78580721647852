import React from "react";
import { Row, Col, Table, Pagination, PaginationItem } from "reactstrap";
import useSWR from "swr";
import SalonFactory from "../SalonFinder/SalonFactory";
import { useLocation, Link } from "react-router-dom";
import SalonListItem from "./SalonListItem";
import { Helmet } from "react-helmet";
import fetcher from "../../utilities/fetcher";

export default function SalonListing() {
  //const [search, setSearch] = useState("");

  const queryParams = new URLSearchParams(useLocation().search);
  const currentPage = parseInt(queryParams.get("page") ?? 1);

  const { data: salons } = useSWR(
    [`/api/salon/find`, "", currentPage, 10, "name", "asc"],
    SalonFactory.Find
  );

  const { data: brands } = useSWR("/api/brand/get", fetcher);
  const options =
    brands && brands.map((item) => ({ value: item.id, label: item.name }));

  return (
    <>
      <Row>
        <Helmet>
          <title>Salon Listing</title>
        </Helmet>
        <Col>
          <Table striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
                <th>Contact Info</th>
                <th>Opens</th>
                <th>Brands</th>
                <th style={{ width: `125px` }}></th>
              </tr>
            </thead>
            <tbody style={{ minHeight: `490px`, height: `490px` }}>
              {salons &&
                salons.map((salon) => (
                  <SalonListItem key={salon.id} {...salon} options={options} />
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination>
            <PaginationItem>
              <Link
                to={`/salons/?page=${
                  currentPage - 1 > 0 ? currentPage - 1 : currentPage
                }`}
              >
                Previous
              </Link>
            </PaginationItem>
            <PaginationItem>
              <Link to={`/salons/?page=${currentPage + 1}`}>Next</Link>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </>
  );
}
