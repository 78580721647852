import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { Row, Col, FormGroup, Input, Label, Button, Alert } from "reactstrap";
import useSWR from "swr";
import fetcher from "../../utilities/fetcher";
import authService from "../api-authorization/AuthorizeService";

export default function Settings() {
  const { data } = useSWR("/api/ratelimit/get/", fetcher);
  const [period, setPeriod] = useState("");
  const [limit, setLimit] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState("");
  const [salons, setSalons] = useState(0);
  const [alertVisible, setAlertVisible] = useState(false);

  useEffect(() => {
    if (data) {
      const { period, limit, salons } = data;
      setPeriod(period);
      setLimit(limit);
      setSalons(salons);
    }
  }, [data]);

  const saveRateLimit = async (event) => {
    event.preventDefault();
    const token = await authService.getAccessToken();
    setIsSaving(true);
    await fetch("/api/ratelimit/post", {
      method: "POST",
      headers: !token
        ? {
            "Content-Type": "application/json",
          }
        : {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
      body: JSON.stringify({
        Id: 1,
        Period: period,
        Limit: limit,
        Salons: salons,
      }),
    })
      .then((res) => {
        setMessage("Settings successfully saved.");
        setAlertVisible(true);
        return res.json();
      })
      .catch((err) => console.log(err))
      .finally(() => setIsSaving(false));
  };

  const handleLimitChange = (event) => {
    var value = event.target.value;
    setLimit(value);
  };
  const handlePeriodChange = (event) => {
    var value = event.target.value;
    setPeriod(value);
  };

  const handleSalonChange = (event) => {
    var value = event.target.value;
    setSalons(value);
  };

  const onAlertDismissed = () => {
    setAlertVisible(false);
    setMessage("");
  };

  return (
    <div>
      <h1>Settings</h1>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      {message && (
        <Row>
          <Col>
            <Alert
              isOpen={alertVisible}
              toggle={onAlertDismissed}
              color="success"
            >
              {message}
            </Alert>
          </Col>
        </Row>
      )}

      <form onSubmit={saveRateLimit}>
        <Row>
          <Col xs={12} sm={6}>
            <FormGroup>
              <Label>Limit</Label>
              <Input type="number" value={limit} onChange={handleLimitChange} />
            </FormGroup>
            <FormGroup>
              <Label>Period</Label>
              <Input type="text" value={period} onChange={handlePeriodChange} />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <Label>No. of salons per page</Label>
              <Input
                type="number"
                value={salons}
                onChange={handleSalonChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Button disabled={isSaving} type="submit" color="primary">
                Save
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </form>
    </div>
  );
}
