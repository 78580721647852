import React from "react";

const SalonFinderContext = React.createContext({
  selectedSalon: {},
  selectedLanguage: {},
  brands: [],
  setSelectedSalon: () => {},
  setLanguage: () => {},
  setBrands: () => {},
  page: 1,
  setPage: () => {},
  rateLimit: {},
  setRateLimit: () => {},
});

export default SalonFinderContext;
