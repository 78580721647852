import React from "react";

export function CallIcon({
  style = { fill: `#b40023` },
  className = "",
  width = 14,
  height = 14,
}) {
  const a = Object.assign({ fill: `#b40023` }, style);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className={className}
    >
      <defs></defs>
      <path
        className="a"
        style={a}
        d="M43.314,46.009a5.888,5.888,0,0,1-2.75-.938,17.88,17.88,0,0,1-4.319-3.231A16.39,16.39,0,0,1,33,37.523c-1.151-2.094-.955-3.191-.736-3.66a2.922,2.922,0,0,1,1.145-1.229,5.51,5.51,0,0,1,.9-.475l.086-.038a.836.836,0,0,1,.686-.062,2.009,2.009,0,0,1,.652.5,10.835,10.835,0,0,1,1.63,2.42,2.411,2.411,0,0,1,.32.991,1.566,1.566,0,0,1-.4.932c-.041.056-.082.109-.121.161-.238.313-.29.4-.256.564a5.928,5.928,0,0,0,1.443,2.141,5.682,5.682,0,0,0,2.116,1.408c.168.036.26-.018.583-.265l.143-.108a1.574,1.574,0,0,1,.946-.423h0a2.264,2.264,0,0,1,1,.349,11.938,11.938,0,0,1,2.411,1.618,2.009,2.009,0,0,1,.5.65.843.843,0,0,1-.062.688c-.012.026-.024.054-.038.086a5.515,5.515,0,0,1-.478.893,2.922,2.922,0,0,1-1.231,1.143A2.107,2.107,0,0,1,43.314,46.009Z"
        transform="translate(-32.098 -32.009)"
      />
    </svg>
  );
}

export function PinIcon({
  style = { fill: `#b40023` },
  className = "",
  width = 10,
  height = 14,
}) {
  const a = Object.assign({ fill: `#b40023` }, style);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className={className}
    >
      <defs></defs>
      <g transform="translate(-96 -32)">
        <path
          className="a"
          style={a}
          d="M101,32a4.9,4.9,0,0,0-5,4.781,11.146,11.146,0,0,0,1.7,4.962,36.668,36.668,0,0,0,2.5,3.851.992.992,0,0,0,1.6,0,36.812,36.812,0,0,0,2.5-3.851,11.157,11.157,0,0,0,1.7-4.962A4.9,4.9,0,0,0,101,32Zm0,7a2,2,0,1,1,2-2A2,2,0,0,1,101,39Z"
          transform="translate(0)"
        />
      </g>
    </svg>
  );
}

export function ClockIcon({
  style = {},
  className = "",
  width = 16,
  height = 16,
}) {
  const a = Object.assign(
    {
      fill: `none`,
      stroke: `#b40023`,
      strokeWidth: `2px`,
      strokeMiterlimit: `10`,
    },
    style
  );

  const b = Object.assign(
    {
      fill: `none`,
      stroke: `#b40023`,
      strokeWidth: `2px`,
      strokeLinecap: `round`,
      strokeLinejoin: `round`,
    },
    style
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className={className}
    >
      <defs></defs>
      <g transform="translate(-63 -63)">
        <path
          className="a"
          style={a}
          d="M71,64a7,7,0,1,0,7,7A7,7,0,0,0,71,64Z"
        />
        <path
          className="b"
          style={b}
          d="M256,128v4.373h3.5"
          transform="translate(-185 -60.79)"
        />
      </g>
    </svg>
  );
}

export function CloseIcon({ style }) {
  const styles = Object.assign({ fill: `#6a6a6c` }, style);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="18px"
      height="18px"
      style={styles}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
  );
}
