import { useState, useEffect } from "react";

export default function useGeolocation() {
  const [position, setPosition] = useState(null);

  useEffect(() => {
    const onSuccess = (position) => {
      setPosition(position);
    };

    const onError = (err) => {
      console.log("Unable to get position", err);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
  }, []);

  const refreshLocation = () => {
    const onSuccess = (position) => {
      setPosition(position);
    };

    const onError = (err) => {
      console.log("Unable to get position", err);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
  };

  return { position, refreshLocation };
}
