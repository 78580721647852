import React from "react";

import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import Skeleton from "react-loading-skeleton";

export default function SalonItemSkeleton() {
  return (
    <Card>
      <CardBody>
        <CardTitle>
          <div className="w-100">
            <Skeleton height={24} width={`100%`} />
          </div>
        </CardTitle>
        {new Array(3).fill().map((_, index) => (
          <CardText className="d-flex" key={index}>
            <div>
              <Skeleton circle={true} height={16} width={16} className="mr-2" />
            </div>
            <div style={{ flex: `0 1 100%` }}>
              <Skeleton height={16} width={`100%`} />
            </div>
          </CardText>
        ))}
        <CardText className="d-flex">
          <div style={{ flex: `0 1 100%` }}>
            <Skeleton height={16} width={`100%`} />
          </div>
        </CardText>
      </CardBody>
    </Card>
  );
}
