import React, { useState, useEffect, useContext } from "react";
import { Button } from "reactstrap";
import qs from "querystring";
import fetcher from "../../utilities/fetcher";
import SalonItem from "./SalonItem";
import { useSWRInfinite } from "swr";
import SalonItemSkeleton from "./SalonItemSkeleton";
import SalonFinderContext from "./SalonFinderContext";
import { useLocation } from "react-router-dom";

export default function SalonList({
  search,
  onSelect,
  currentPosition,
  userLocation,
}) {
  const [searchTerm, setSearchTerm] = useState(search);
  const { selectedLanguage, brands, setPage, rateLimit } =
    useContext(SalonFinderContext);

  const location = useLocation();
  let country = "";
  let _brand = "";
  let _address = "";
  if (location.search) {
    var query = new URLSearchParams(location.search);
    country = query.get("country");
    _brand = query.get("brands");
    _address = query.get("address");
  }
  const {
    data,
    error,
    setSize,
    size: swrSize,
  } = useSWRInfinite((index, prevData) => {
    const queryString = {
      country,
      size: rateLimit ? rateLimit.salons : 10,
      orderby: "name",
      direction: "asc",
      search: searchTerm.search,
      num: index + 1,
      address: _address,
    };
    if (currentPosition) {
      queryString.lat = currentPosition.coords.latitude;
      queryString.lng = currentPosition.coords.longitude;
    } else if (userLocation) {
      queryString.lat = userLocation.lat;
      queryString.lng = userLocation.lng;
    }

    if (_brand) {
      if (brands) {
        var _selectedBrand = brands.filter(
          (brand) => brand.name.toLowerCase() === _brand.toLowerCase()
        );
        if (_selectedBrand.length > 0) {
          queryString.brands = _selectedBrand[0].id;
        }
      } else {
        return null;
      }
    }

    if (searchTerm.brand.length > 0) {
      queryString.brands = searchTerm.brand;
    }

    if (prevData && !prevData.length) return null;
    return `/api/salon/find/?${qs.stringify(queryString)}`;
  }, fetcher);

  useEffect(() => {
    setSearchTerm(search);
  }, [search]);

  useEffect(() => {
    setPage(swrSize);
  }, [swrSize]);

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (swrSize > 0 && data && typeof data[swrSize - 1] === "undefined");
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < swrSize);
  const salonList = data ? [].concat(...data) : [];
  return (
    <div className="w-100 h-100 bg-white">
      {isEmpty && (
        <div className="d-flex justify-content-center py-4">
          <strong style={{ fontSize: `1.25rem` }}>
            {selectedLanguage?.["no_results"]?.value}
          </strong>
        </div>
      )}
      {salonList.map((item) => (
        <SalonItem
          onSelect={() => onSelect({ lat: item.latitude, lng: item.longitude })}
          key={item.id}
          {...item}
        />
      ))}
      {isLoadingMore &&
        new Array(3)
          .fill()
          .map((item, index) => <SalonItemSkeleton key={index} />)}
      {!isReachingEnd && (
        <Button
          onClick={() => {
            // setPage(page + 1);
            setSize(swrSize + 1);
          }}
          className={`w-100 ${isLoadingMore ? "d-none" : ""}`}
          disabled={isLoadingMore}
        >
          More
        </Button>
      )}
    </div>
  );
}
