import React, { Component } from "react";
import {
  FormGroup,
  Input,
  Row,
  Col,
  Table,
  UncontrolledAlert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import { Helmet } from "react-helmet";
import authService from "./api-authorization/AuthorizeService";

export class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);
    this.state = {
      salonList: [],
      isUploading: false,
      isSaving: false,
      isSuccess: false,
      responseMessage: "",
      confirmModal: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
  }

    toggleConfirmModal() {
      this.setState({
         confirmModal: !this.state.confirmModal
      });
  }

    async handleSubmit(event) {
        console.log("working");
    event.preventDefault();
    this.setState({ isUploading: true, confirmModal: false });
    const formData = new FormData(event.target);
    const token = await authService.getAccessToken();
    const response = await fetch("/api/salon/upload", {
      method: "POST",
      body: formData,
      headers: !token ? {} : { Authorization: `Bearer ${token}` },
    })
        .then((res) => {
        if (res.ok) {
            console.log(res);
          return res.json();
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch((res) => this.setState({ isSuccess: false, responseMessage: res }))
      .finally(() => this.setState({ isUploading: false }));
     console.log(response);
    if (response) {
      this.setState({
        salonList: response,
        isSuccess: true,
        responseMessage: `New ${
          response.length > 1 ? `Salons were` : `Salon was`
        } successfully added.`,
      });
    }
  }

  async handleSave() {
    const token = await authService.getAccessToken();
    const { salonList } = this.state;
    this.setState({ isSaving: true });
    await fetch("/api/salon/BulkInsert", {
      method: "POST",
      body: JSON.stringify(salonList),
      headers: !token
        ? {
            "Content-Type": "application/json",
          }
        : {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
    })
      .then((res) => this.setState({ salonList: [] }))
      .catch((res) => console.log(res))
      .finally((res) => this.setState({ isSaving: false }));
  }

  render() {
    const { salonList, isUploading, isSuccess, responseMessage } = this.state;
    return (
      <div>
        <h1>Salon Master data</h1>
        <Helmet>
          <title>Uploading</title>
        </Helmet>
        <Row>
          <Col xs={12} sm={6}>
            <div>
              <form onSubmit={this.handleSubmit} encType="multipart/form-data" id="upload-form">
                <FormGroup>
                  <Input type="file" id="file" name="file" />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="button"
                    name="button"
                    value={isUploading ? "Uploading..." : "Upload"}
                    disabled={isUploading}
                    onClick={this.toggleConfirmModal}
                  />
                </FormGroup>
                <Modal isOpen={this.state.confirmModal} toggle={this.toggleConfirmModal} className={this.props.className}>
                    <ModalHeader toggle={this.toggleConfirmModal}>Are you sure you want to upload a new file?</ModalHeader>
                    <ModalBody>
                        Clicking "<b>Continue</b>" will delete all existing records in the database and will be replaced by the data from this file you are trying to upload.
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" form="upload-form" color="success">Continue</Button>{' '}
                        <Button color="secondary" onClick={this.toggleConfirmModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
              </form>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {responseMessage && (
              <UncontrolledAlert color={`${isSuccess ? `success` : `danger`}`}>
                {responseMessage}
              </UncontrolledAlert>
            )}
          </Col>
        </Row>
        {salonList.length > 0 && (
          <>
            <Row>
              <Col>
                <Table striped>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Contact Info</th>
                      <th>Email Addres</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salonList.map((salon) => (
                      <tr key={salon.name}>
                        <td>{salon.name}</td>
                        <td>{salon.address}</td>
                        <td>{salon.contactNo}</td>
                        <td>{salon.emailAddress}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}
