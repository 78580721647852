import React, { useState, useEffect } from "react";
import { Input, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faEdit,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import authService from "../api-authorization/AuthorizeService";
import Select from "react-select";

export default function SalonListItem({
  id,
  name,
  address,
  contactNo,
  salonBrands,
  opens,
  latitude,
  longitude,
  options,
}) {
  const [edit, setEdit] = useState(false);

  const [_name, setName] = useState(name);
  const [_address, setAddress] = useState(address);
  const [_contactNo, setContactNo] = useState(contactNo);
  const [_opens, setOpens] = useState(opens);
  const [_brands, setBrands] = useState([]);

  const brands =
    salonBrands && salonBrands.map((salonBrand) => salonBrand.brand.name);

  const handleSave = async () => {
    var token = await authService.getAccessToken();

    const _salonBrands = [];
    _brands.forEach((brand) => {
      const salonBrand = { brand: { id: brand.value }, salon: { id } };
      _salonBrands.push(salonBrand);
    });

    await fetch("/api/salon/update", {
      method: "POST",
      body: JSON.stringify({
        id,
        latitude,
        longitude,
        name: _name,
        opens: _opens,
        address: _address,
        contactNo: _contactNo,
        salonBrands: _salonBrands,
      }),
      headers: !token
        ? {
            "Content-Type": "application/json",
          }
        : {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
    })
      .then((res) => res.json())
      .catch((res) => console.log(res));
    setEdit(false);
  };

  useEffect(() => {
    const optionValues =
      salonBrands &&
      salonBrands.map((salonbrand) => ({
        value: salonbrand.brand.id,
        label: salonbrand.brand.name,
      }));
    setBrands(optionValues);
  }, [salonBrands]);

  return (
    <tr key={id}>
      <td>
        {edit ? (
          <Input
            type="text"
            onChange={(event) => setName(event.target.value)}
            value={_name}
          />
        ) : (
          _name
        )}
      </td>
      <td>
        {edit ? (
          <Input
            type="text"
            onChange={(event) => setAddress(event.target.value)}
            value={_address}
          />
        ) : (
          _address
        )}
      </td>
      <td>
        {edit ? (
          <Input
            type="text"
            onChange={(event) => setContactNo(event.target.value)}
            value={_contactNo}
          />
        ) : (
          _contactNo
        )}
      </td>
      <td>
        {edit ? (
          <Input
            type="text"
            onChange={(event) => setOpens(event.target.value)}
            value={_opens}
          />
        ) : (
          _opens
        )}
      </td>
      <td>
        {edit ? (
          <Select
            isMulti
            isClearable
            options={options}
            classNamePrefix="react-select"
            styles={{
              control: (provided) => {
                return {
                  ...provided,
                  minHeight: `53px`,
                  backgroundColor: `#f2f4f5`,
                  borderRadius: `0`,
                };
              },
            }}
            onChange={(values) => setBrands(values)}
            value={_brands}
          />
        ) : (
          brands.join(", ")
        )}
      </td>
      <td>
        {!edit ? (
          <>
            <Button className="p-2" color="link" onClick={() => setEdit(true)}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>{" "}
            {/* |
            <Button className="p-2" color="link">
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button> */}
          </>
        ) : (
          <>
            <Button className="p-2" color="link" onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} />
            </Button>{" "}
            |
            <Button className="p-2" color="link" onClick={() => setEdit(false)}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </Button>
          </>
        )}
      </td>
    </tr>
  );
}
