import React, { useState, useEffect, useRef, useContext } from "react";
import GoogleMapReact from "google-map-react";
import SalonMarker from "./SalonMarker";
import useSWR from "swr";
import fetcher from "../../utilities/fetcher";
import qs from "querystring";
import { useLocation } from "react-router-dom";
import SalonFinderContext from "./SalonFinderContext";

export default function SalonMaps({
  search,
  panTo,
  currentPosition,
  refreshLocation,
  setUserLocation,
  userLocation,
}) {
  const [googleMaps, setGoogleMaps] = useState(null);
  const [searchTerm, setSearchTerm] = useState({ search: "", brand: 0 });
  const [bounds, setBounds] = useState(null);
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  const { brands, page, rateLimit } = useContext(SalonFinderContext);
  const location = useLocation();
  let country = "";
  let _brand = "";
  if (location.search) {
    var query = new URLSearchParams(location.search);
    country = query.get("country");
    _brand = query.get("brands");
  }

  const { data } = useSWR(() => {
    var queryString = {
      country,
      size: rateLimit ? rateLimit.salons : 10,
      num: page ?? 1,
      search: searchTerm.search,
    };
    if (_brand) {
      if (brands) {
        var _selectedBrand = brands.filter(
          (brand) => brand.name.toLowerCase() === _brand.toLowerCase()
        );
        if (_selectedBrand.length > 0) {
          queryString.brands = _selectedBrand[0].id;
        }
      } else {
        return null;
      }
    }

    if (searchTerm.brand.length > 0) {
      queryString.brands = searchTerm.brand;
    }

    if (currentPosition) {
      queryString.lat = currentPosition.coords.latitude;
      queryString.lng = currentPosition.coords.longitude;
    } else if (userLocation) {
      queryString.lat = userLocation.lat;
      queryString.lng = userLocation.lng;
    }

    return `/api/salon/GetMarkers/?${qs.stringify(queryString)}`;
  }, fetcher);

  const customControl = useRef(null);

  const panToSelected = (coordinates) => {
    const { map } = googleMaps;
    map.panTo(coordinates);
  };

  const handleGoogleApiLoaded = ({ map, maps }) => {
    const isMobile = !window.matchMedia("(min-width:768px)").matches;

    if (isMobile) {
      map.setOptions({
        streetViewControl: false,
        zoomControl: false,
        fullscreenControl: false,
      });
    } else {
      map.setOptions({
        fullscreenControl: true,
        zoomControl: true,
        zoomControlOptions: {
          position: maps.ControlPosition.RIGHT_BOTTOM,
        },
        //streetViewControl: true,
        streetViewControl: {
          position: maps.ControlPosition.RIGHT_BOTTOM,
        },
      });
    }

    setGoogleMaps({ map, maps });
  };

  useEffect(() => {
    setSearchTerm(search);
  }, [search]);

  useEffect(() => {
    if (googleMaps && currentPosition) {
      const latlng = {
        lat: currentPosition.coords.latitude,
        lng: currentPosition.coords.longitude,
      };
      const { map } = googleMaps;
      map.panTo(latlng);
    }
  }, [googleMaps, currentPosition]);

  useEffect(() => {
    const isHdpi = window.matchMedia("(min-width:1280px)").matches;

    if (googleMaps && panTo) {
      const offsetX = isHdpi ? -100 : 0;
      const offsetY = isHdpi ? -100 : -125;
      const { map, maps } = googleMaps;
      const projection = map.getProjection();
      const latlng = new maps.LatLng(panTo.lat, panTo.lng);
      const aPoint = projection.fromLatLngToPoint(latlng);
      const zoom = Math.pow(2, map.getZoom());
      const pointOffset = new maps.Point(
        offsetX / zoom || 0,
        offsetY / zoom || 0
      );
      aPoint.x = aPoint.x + pointOffset.x;
      aPoint.y = aPoint.y + pointOffset.y;
      const newCoords = map.getProjection().fromPointToLatLng(aPoint);
      map.panTo(newCoords);
    }
  }, [panTo, googleMaps]);

  useEffect(() => {
    if (googleMaps) {
      const isMobile = !window.matchMedia("(min-width:768px)").matches;

      if (!isMobile) {
        const { map, maps } = googleMaps;
        map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(
          customControl.current
        );
      }
    }
  }, [googleMaps]);

  useEffect(() => {
    if (googleMaps && data) {
      const { maps } = googleMaps;
      const { markers } = data;

      // if (currentLocation.latitude !== 0 && currentLocation.longitude !== 0) {
      //   panToSelected(
      //     new maps.LatLng(currentLocation.latitude, currentLocation.longitude)
      //   );
      // }

      const _bounds = new maps.LatLngBounds();

      _bounds.extend(bounds.nw);
      _bounds.extend(bounds.ne);
      _bounds.extend(bounds.sw);
      _bounds.extend(bounds.se);

      const _visibleMarkers = markers
        ? markers.filter((item) => {
            const latlng = new maps.LatLng(item.latitude, item.longitude);
            return _bounds.contains(latlng);
          })
        : [];

      // markers.forEach((item) => {
      //   const latlng = new maps.LatLng(item.latitude, item.longitude);
      //   _bounds.extend(latlng);
      // });

      //map.fitBounds(_bounds);

      // if (_visibleMarkers.length === 0) {
      //   map.setZoom(9);
      // }

      setVisibleMarkers(_visibleMarkers);
    }
  }, [googleMaps, data, bounds]);

  useEffect(() => {
    if (googleMaps && data) {
      const { maps, map } = googleMaps;
      const { currentLocation } = data;
      if (currentLocation.latitude !== 0 && currentLocation.longitude !== 0) {
        if (searchTerm.search) {
          map.setZoom(11);
        } else {
          map.setZoom(7);
        }

        panToSelected(
          new maps.LatLng(currentLocation.latitude, currentLocation.longitude)
        );
      }
    }
  }, [googleMaps, data, searchTerm]);

  useEffect(() => {
    if (location.search && googleMaps) {
      const { maps, map } = googleMaps;
      const query = new URLSearchParams(location.search);
      const country = query.get("country");
      const address = query.get("address");

      const geocoder = new maps.Geocoder();
      geocoder.geocode(
        { address: address ? address : country },
        (results, status) => {
          if (status === "OK") {
            const center = results[0].geometry.location;
            setUserLocation({
              lat: center.lat(),
              lng: center.lng(),
            });

            map.setCenter(center);
            map.setZoom(10);

            if (address) {
              map.setZoom(9);
              new maps.Circle({
                map,
                strokeColor: "#b40023",
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillColor: "#b40023",
                fillOpacity: 0.1,
                center: center,
                radius: 50000,
              });
            } else {
              map.setZoom(10);
            }
          }
        }
      );
    }
  }, [googleMaps, location]);

  //var markers = data && data.markers ? data.markers : [];

  return (
    <>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={{ lat: 55.9981888, lng: 12.5037874 }}
        defaultZoom={13}
        bootstrapURLKeys={{ key: "AIzaSyCenzRXH8L3hDrYcfnoO1IyOo1i4V_8Ea0" }}
        onGoogleApiLoaded={handleGoogleApiLoaded}
        onChange={({ bounds }) => setBounds(bounds)}
      >
        {visibleMarkers.map((marker) => (
          <SalonMarker
            id={marker.id}
            lat={marker.latitude}
            lng={marker.longitude}
            key={marker.id}
            panToSelected={panToSelected}
          />
        ))}
      </GoogleMapReact>
      <div className="d-none">
        <div
          className="gm-uselocation"
          ref={customControl}
          onClick={() => refreshLocation()}
        >
          <div className="gm-uselocation-icon"></div>
        </div>
      </div>
    </>
  );
}
