import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import SalonFinder from "./components/SalonFinder/SalonFinder";
import SalonListing from "./components/SalonListing/SalonListing";
import "./custom.css";
import DictionaryList from "./components/Dictionary/DictionaryList";
import Settings from "./components/SalonFinder/Settings";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Switch>
        <Route path="/salon-finder" component={SalonFinder} />
        <Layout>
          <AuthorizeRoute exact path="/" component={Home} />
          <AuthorizeRoute exact path="/salons/" component={SalonListing} />
          <AuthorizeRoute
            exact
            path="/dictionary/"
            component={DictionaryList}
          />
          <AuthorizeRoute exact path="/settings/" component={Settings} />
          <Route
            path={ApplicationPaths.ApiAuthorizationPrefix}
            component={ApiAuthorizationRoutes}
          />
        </Layout>
      </Switch>
    );
  }
}
