import axios from "axios";
import qs from "querystring";

class SalonFactory {
  static async Find(url, search, num, size, orderby, direction) {
    var queryString = {
      search,
      num,
      size,
      orderby,
      direction,
    };
    return axios
      .get(`${url}/?${qs.stringify(queryString)}`)
      .then((res) => res.data);
  }
}

export default SalonFactory;
