import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import SalonList from "./SalonList";
import SalonMaps from "./SalonMaps";
import { Helmet } from "react-helmet";
import SalonSearchForm from "./SalonSearchForm";
import SalonFinderContext from "./SalonFinderContext";
import useSWR from "swr";
import fetcher from "../../utilities/fetcher";
import useGeolocation from "../../utilities/useGeolocation";
import { useLocation } from "react-router-dom";

export default function SalonFinder() {
  const [search, setSearch] = useState({ search: "", brand: 0 });
  const [panTo, setPanTo] = useState(null);
  const [isShowMap, setIsShowMap] = useState(false);
  const [userLocation, setUserLocation] = useState(null);

  const handleSelect = ({ lat, lng }) => setPanTo({ lat, lng });

  const [selectedSalon, setSelectedSalon] = useState({});
  const [selectedLanguage, setLanguage] = useState(null);
  const [brands, setBrands] = useState(null);
  const [page, setPage] = useState(1);
  const [rateLimit, setRateLimit] = useState(null);

  const { position: currentPosition, refreshLocation } = useGeolocation();
  useEffect(() => {
    if (selectedSalon.lat && selectedSalon.lng) {
      setPanTo({ lat: selectedSalon.lat, lng: selectedSalon.lng });
    }
  }, [selectedSalon]);

  const { data: lang } = useSWR("/api/dictionary/get", fetcher);
  const { data: rate } = useSWR("/api/ratelimit/get", fetcher);

  const location = useLocation();

  useEffect(() => {
    if (lang) {
      var browserLang = "en";

      if (location.search) {
        var query = new URLSearchParams(location.search);
        browserLang = query.get("lang");
      }

      const selectedLang = {};
      lang.forEach((item) => {
        if (item.isoCode === browserLang) {
          selectedLang[item.key] = { value: item.value };
        }
      });
      setLanguage(selectedLang);
    }
  }, [lang, location]);

  useEffect(() => {
    setRateLimit(rate);
  }, [rate]);

  return (
    <Container fluid style={{ height: `100vh` }} className="pb-3">
      <Helmet>
        <title>
          {selectedLanguage
            ? selectedLanguage?.["find_salon_near_you"]?.value
            : `Find a Certified Salon`}
        </title>
      </Helmet>
      <SalonFinderContext.Provider
        value={{
          selectedSalon,
          setSelectedSalon,
          selectedLanguage,
          setLanguage,
          brands,
          setBrands,
          page,
          setPage,
          rateLimit,
        }}
      >
        <SalonSearchForm onSearch={(value) => setSearch(value)} />
        <Row>
          <Col
            sm="12"
            md="6"
            lg="8"
            className={`salon-map ${isShowMap && "show"}`}
          >
            <SalonMaps
              search={search}
              panTo={panTo}
              currentPosition={currentPosition}
              refreshLocation={refreshLocation}
              setUserLocation={setUserLocation}
              userLocation={userLocation}
            />
          </Col>
          <Col sm="12" md="6" lg="4" className="salon-list px-0 bg-white">
            <SalonList
              search={search}
              onSelect={handleSelect}
              currentPosition={currentPosition}
              userLocation={userLocation}
            />
          </Col>
        </Row>
      </SalonFinderContext.Provider>
    </Container>
  );
}
