import React, { useEffect, useState } from "react";
import { Row, Col, Table, Input, Button } from "reactstrap";
import useSWR from "swr";
import fetcher from "../../utilities/fetcher";
import { Helmet } from "react-helmet";
import authService from "../api-authorization/AuthorizeService";

export default function DictionaryList() {
  const [tempData, setTempData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const { data } = useSWR("/api/dictionary/getall", fetcher);

  useEffect(() => {
    setTempData(data);
  }, [data]);

  const handleChange = (dictionaryIndex, langIndex) => (event) => {
    const _data = Object.assign([], tempData);
    _data[dictionaryIndex].languages[langIndex].value = event.target.value;
    setTempData(_data);
  };

  const handleSave = async () => {
    const token = await authService.getAccessToken();
    setIsSaving(true);
    await fetch("/api/dictionary/save", {
      method: "POST",
      headers: token ? { Authorization: `Bearer ${token}` } : {},
      body: JSON.stringify(tempData),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err))
      .finally(() => setIsSaving(false));
  };

  return (
    <>
      <Helmet>
        <title>Dictionary</title>
      </Helmet>
      <Row>
        <Col>
          <Table stripe>
            <tbody>
              {data &&
                data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.key}</td>
                    {item.languages.map((lang, langindex) => (
                      <td key={lang.id}>
                        <Input
                          type="text"
                          defaultValue={lang.value}
                          onBlur={handleChange(index, langindex)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {data && (
        <Row>
          <Col>
            <Button disabled={isSaving} onClick={handleSave}>
              Save
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
}
