import React, { useState, useEffect, useContext } from "react";
import SalonItem from "./SalonItem";
import useSWR from "swr";
import fetcher from "../../utilities/fetcher";
import SalonItemSkeleton from "./SalonItemSkeleton";
import SalonFinderContext from "./SalonFinderContext";
// import { PinIcon, CloseIcon } from "../Icons";
import pinIcon from "../../assets/images/location.svg";

export default function SalonMarker({ id, lat, lng, panToSelected }) {
  const { selectedSalon, setSelectedSalon } = useContext(SalonFinderContext);

  const [showInfo, setShowInfo] = useState(false);

  const { data: details } = useSWR(
    showInfo ? `/api/salon/get/${id}` : null,
    fetcher
  );

  useEffect(() => {
    if (selectedSalon.lat && selectedSalon.lng) {
      if (selectedSalon.id === id) {
        setShowInfo(true);
      } else {
        setShowInfo(false);
      }
    } else {
      setShowInfo(false);
    }
  }, [selectedSalon, id]);

  const handleClick = () => {
    if (selectedSalon.id === id) {
      setSelectedSalon({});
    } else {
      setSelectedSalon({ id, lat, lng });
    }
  };

  return (
    <div>
      <span
        className={`map-marker ${showInfo ? `selected` : ``}`}
        onClick={handleClick}
      >
        {!showInfo ? (
          <img src={pinIcon} alt="" />
        ) : (
          <span className="circle-pin"></span>
        )}
      </span>
      {showInfo && (
        <div className={`info-window ${showInfo ? `d-block` : `d-none`}`}>
          {details ? <SalonItem isInMap {...details} /> : <SalonItemSkeleton />}
          <div className="arrow"></div>
        </div>
      )}
    </div>
  );
}
